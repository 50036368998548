exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-screens-about-page-about-page-js": () => import("./../../../src/screens/AboutPage/AboutPage.js" /* webpackChunkName: "component---src-screens-about-page-about-page-js" */),
  "component---src-screens-book-call-book-call-js": () => import("./../../../src/screens/BookCall/BookCall.js" /* webpackChunkName: "component---src-screens-book-call-book-call-js" */),
  "component---src-screens-case-studies-case-studies-js": () => import("./../../../src/screens/CaseStudies/CaseStudies.js" /* webpackChunkName: "component---src-screens-case-studies-case-studies-js" */),
  "component---src-screens-dyna-service-service-page-js": () => import("./../../../src/screens/DynaService/ServicePage.js" /* webpackChunkName: "component---src-screens-dyna-service-service-page-js" */),
  "component---src-screens-process-process-page-js": () => import("./../../../src/screens/Process/ProcessPage.js" /* webpackChunkName: "component---src-screens-process-process-page-js" */),
  "component---src-screens-service-page-service-page-js": () => import("./../../../src/screens/ServicePage/ServicePage.js" /* webpackChunkName: "component---src-screens-service-page-service-page-js" */),
  "component---src-screens-shorten-link-shorten-link-js": () => import("./../../../src/screens/ShortenLink/ShortenLink.js" /* webpackChunkName: "component---src-screens-shorten-link-shorten-link-js" */)
}

